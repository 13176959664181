import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, filter, finalize, map, of, switchMap, tap, throwError } from 'rxjs';
import { authResponseDTO } from './models/authResponseDTO';
import { Router } from '@angular/router';
import { getErrorMessage } from './models/errorMap';
import { passwordResetToken } from './components/reset-password/reset-password.component';
import { AuthService } from './auth.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { BaseEndpointService } from '../shared/interfaces/IEndpoint';
import { User } from '../shared/models/user';


export const status = {
  successful: 'SUCCESSFUL',
  failed: 'FAILED',
  notStarted: 'NOT STARTED',
  refreshing: 'IN PROGRESS'
} as const;

type StatusValues = typeof status[keyof typeof status];


interface RefreshResult {
  isRefreshing: boolean;
  status: StatusValues;
}

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseEndpointService<'account/'> {

  private readonly authService:AuthService = inject(AuthService);
  private readonly permissionsService: NgxPermissionsService = inject(NgxPermissionsService);

  constructor() {

    super({routeTemplate: 'account/'})

    this.authService.isLoggedIn$.asObservable()
    .pipe(
      filter((value) => value == true),
      switchMap(() => this.roles())
    )
    .subscribe((data: string[]) => {

      this.permissionsService.loadPermissions(data)
    });
  }

  resetPasswordWithToken(model:passwordResetToken)
  {
    return this.http.post(this.endpoint + 'token-password-reset',{
      ...model
    })
  }

  forgotPassword(email:string)
  {
    return this.http.post(this.endpoint + 'forgot-password', {email})
  }


isAdmin(): Observable<boolean> {
return this.http
  .get<{ isAdmin: boolean }>(this.endpoint + 'is-admin', {
    withCredentials: true,
  })
  .pipe(map((response) => response.isAdmin));
}

roles(): Observable<any> {
return this.http.get(this.endpoint + 'roles', {
  withCredentials: true,
});
}

me(){
return this.http.get<User>(this.endpoint + 'me', {
  withCredentials:true
})
}

resetPassword(model:any)
{
return this.http.post(`${this.endpoint}reset-password`,{...model})
}

}
